import { render, staticRenderFns } from "./rreach.vue?vue&type=template&id=647bfb20&scoped=true&"
import script from "./rreach.vue?vue&type=script&lang=js&"
export * from "./rreach.vue?vue&type=script&lang=js&"
import style0 from "./rreach.vue?vue&type=style&index=0&id=647bfb20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647bfb20",
  null
  
)

export default component.exports