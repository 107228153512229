<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flexspabet"
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div>
					<!-- <el-button @click="showEdit=true" class="ele-btn-icon addbtn" size="small"
						v-if="permission.includes('sys:rreach:add')">添加违约金设置
					</el-button> -->
					<el-button @click="showEdit=true" class="ele-btn-icon addbtn mb-20" size="small" v-if="permission.includes('sys:rreach:add')">添加违约金设置
					</el-button>
				</div>
				<div style="display: flex;">
					<el-form-item label="订单类型:" label-width="80px" class="w-250">
						<el-select v-model="table.where.type" @change="handleChangeProv(table.where.type)" placeholder="请选择订单类型" clearable>
							<!-- <el-option label="全部" value="10" /> -->
							<el-option label="巡游出租" value="1" />
							<el-option label="城际定制" value="2" />
							<el-option label="帮我买" value="3" />
							<el-option label="取送件" value="4" />
							<el-option label="超市" value="5" />
							<el-option label="叫餐" value="6" />
							<el-option label="预约单" value="7" />
						</el-select>
					</el-form-item>
					<el-form-item label="省:" label-width="35px" class="w-170">
						<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)"
							placeholder="请选择省" clearable>
							<el-option v-for="option in provArr" :label="option.name" :value="option.pid"
								:key="option.pid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="市:" label-width="35px" class="w-150">
						<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid),$forceUpdate()"
							placeholder="请选择市" clearable>
							<el-option v-for="option in cityArr" :label="option.name" :value="option.cid"
								:key="option.cid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="县/区:" label-width="62px" class="w-200 mr-20">
						<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder="请选择县/区" clearable>
							<el-option v-for="option in districtArr " :label="option.name" :value="option.aid"
								:key="option.aid"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
							class="ele-btn-icon">搜索</el-button>
						<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
					</el-form-item>
				</div>
			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" >
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="140" />
          <el-table-column prop="type_name" label="订单类型" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="open_status" label="状态" show-overflow-tooltip min-width="120">
            <template slot-scope="scope">
              <span>{{scope.row.open_status==1?'开启':scope.row.open_status==0?'关闭':''}}</span>
            </template>
          </el-table-column>
					<el-table-column prop="nums" label="用户免责取消时长（分钟）" show-overflow-tooltip min-width="190" />
					<el-table-column prop="pay_fee" label="违约金金额（元）" show-overflow-tooltip min-width="120" />
					<el-table-column label="更新时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.update_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="操作" width="160px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link slot="reference" @click="set_status(row)"
								:icon="row.open_status==1?'el-icon-error':'el-icon-success'"
								:type="row.open_status==1?'danger':'primary'" :underline="false"
								v-if="permission.includes('sys:rreach:start')">
								{{row.open_status==1?'关闭':row.open_status==0?'开启':''}}
							</el-link>
							<el-link @click="edit(row)" icon="el-icon-edit" type="primary" :underline="false"
								v-if="permission.includes('sys:rreach:edit')">编辑</el-link>
              <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
                       :underline="false" v-if="permission.includes('sys:rreach:delete')">删除</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 编辑弹窗 -->
		<!--添加/编辑弹窗 -->
		<el-dialog v-dialogDrag :title="editForm.id?'查看违约金设置':'添加违约金设置'" :visible.sync="showEdit" @closed="editForm={}" :destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="editForm" ref="editForm" :rules="editRules" label-width="180px">
					<!-- <div class="d-flex ">
						
						<el-form-item label="请选择地区：" v-if="editForm.id" prop="pid">
							<el-select v-model="editForm.pname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择省" disabled
								v-if="editForm.pname">
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cname" class="selectStyle mr-10 mb-20" clearable placeholder="请选择市" disabled
								v-if="editForm.cname">
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aname" class="selectStyle mb-20" clearable
								:class="(editForm.pname && editForm.cname)?'mr-10':''" placeholder="请选择省市区" disabled
								v-if="editForm.aname">
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
						
						<el-form-item label="请选择地区：" v-if="!editForm.id" prop="pid">
							<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)" placeholder='请选择省'
									class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.cid" @change="handleChangeCity1(editForm.cid)"
								placeholder="请选择市" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
									:label="option.name"></el-option>
							</el-select>
							<el-select v-model="editForm.aid" @change="$forceUpdate(),handleDis(editForm.aid)"
								placeholder="请选择省市区" class="selectStyle mr-10 mb-20" clearable>
								<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
									:label="option.name"></el-option>
							</el-select>
						</el-form-item>
					</div> -->
					<el-form-item label="请选择地区：" prop="pid">
						<el-select v-model="editForm.pid" @change="handleChangeProv1(editForm.pid)"
							placeholder='请选择省' class="selectStyle mr-10" clearable>
							<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
								:label="option.name"></el-option>
						</el-select>
						<el-select v-model="editForm.cname" @change="handleChangeCity1(editForm.cname)"
							placeholder="请选择市" class="selectStyle mr-10" clearable>
							<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
								:label="option.name"></el-option>
						</el-select>
						<el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
							placeholder="请选择区/县" class="selectStyle mr-10" clearable>
							<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
								:label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="请选择订单类型：" prop="type">
						<el-select v-model="editForm.type" class="selectStyle input163 mr-10" placeholder="请选择订单类型">
							<el-option v-for="option in typeArr" :value="option.type" :key="option.type" :label="option.name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="可免责取消时长：" prop="nums">
						<el-input v-model="editForm.nums" controls-position="right" placeholder="" clearable
							class="input163" />
						分钟
					</el-form-item>
					<el-form-item label="违约金金额(元)：" prop="pay_fee">
						<el-input v-model="editForm.pay_fee" controls-position="right" placeholder="" clearable
							class="input163" /> 元
					</el-form-item>
				</el-form>
			</el-card>
			<div slot="footer">
				<el-button @click="showEdit=false">取消</el-button>
				<el-button type="primary" @click="save">提交</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		name: "SysLimitNumbers",
		data() {
			return {
				table: {
					url: '/range/damagesList',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: { // 表单验证规则
					pid: [{
						required: true,
						message: '请选择省份',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择订单类型',
						trigger: 'change'
					}],
					nums: [{
						required: true,
						message: '请输入可免责取消时长',
						trigger: 'blur'
					}],
					pay_fee: [{
						required: true,
						message: '请输入违约金金额',
						trigger: 'blur'
					},{
						pattern: /^([0-9][0-9]*)+(.[0-9]{1,2})?$/, 
						message: '请输入大于0的有效数字', 
						trigger: 'blur'
					}]
				},
				provArr: [],
				cityArr: [],
				districtArr: [],
				activeName: 'first',
				activeEdit: 'run-driver',
				pid: '',
				cid: '',
				aid: '',
				changePro: false,
				changeCity: false,
				changeArea: false,
				typeArr:[{
						type:'1',
						name:"巡游出租"
					},{
						type:'2',
						name:"城际定制"
					},{
						type:'3',
						name:"帮我买"
					},{
						type:'4',
						name:"取送件"
					},{
						type:'5',
						name:"超市"
					},{
						type:'6',
						name:"叫餐"
					},{
						type:'7',
						name:"预约单"
					}],
			}
		},
		created() {


			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})

		},
		computed: {
			...mapGetters(["permission"]),
		},
		mounted() {},
		methods: {
			set_status(row) {
				if (row.open_status == 1) {
					this.$confirm('确认关闭违约金设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/range/set_status', {
							id: row.id,
							open_status: '0'
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '关闭成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});


					})
				} else {
					this.$confirm('确认开启违约金设置?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/range/set_status', {
							id: row.id,
							open_status: '1'
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '开启成功'
								});
								this.$refs.table.reload();
								this.faceStart = '关闭'
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});

        })
      }
    },

    /**
     *选择省
     **/
    handleChangeProv(e) {
      /** 获取被选省份的pid**/
      let pid = ''
      this.provArr.forEach(function(item) {
        if (item.pid == e) {
          pid = item.pid
        }
      })
      /** 根据被选省份的pid获取省市下面的市**/
      this.$http.post('/common/city_list', {
        pid: pid
      }).then(res => {
        let data = JSON.parse(res.data)
        this.cityArr = data
        /** 选择省份清空市县**/
        this.table.where.cid = ''
        this.table.where.aid = ''
      })
    },
    /**
     *选择市
     **/
    handleChangeCity(e) {
      if(e){
        /** 获取被选市的cid**/
        let cid = ''
        this.cityArr.forEach(function(item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.table.where.aid = ''
        })
      }else{
        // this.table.where.pid = ''
        this.table.where.aid = ''
      }
    },

    /**
     *修改省
     **/
    handleChangeProv1(e) {
      /** 获取被选省份的pid**/
      if(e){
        let pid = ''
        let that = this
        this.changePro = true
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          that.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cid = ''
          this.editForm.aid = ''
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      }else{
        this.editForm.pid = ''
        this.editForm.cid = ''
        this.editForm.aid = ''
        this.editForm.pname = ''
        this.editForm.cname = ''
        this.editForm.aname = ''
      }

    },
    /**
     *修改市
     **/
    handleChangeCity1(e) {
      if(e){
        /** 获取被选市的cid**/
        let cid = ''
        this.changeCity = true
        this.cityArr.forEach(function(item) {
          if (item.cid == e) {
            cid = item.cid
          }
        })
        /** 根据被选市的cid获取市下面的县**/
        this.$http.post('/common/area_list', {
          cid: cid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.districtArr = data
          /** 选择省份清空县**/
          this.editForm.aid = ''
          this.editForm.aname = ''
        })
      }else{
        // this.editForm.cname = ''
        this.editForm.aid = ''
        this.editForm.aname = ''
      }
    },
    handleDis() {
      this.changeArea = true
    },
    /**
     * 显示编辑
     */
    edit(row) {
      this.pid = row.pid
      this.cid = row.cid
      this.aid = row.aid

      this.editForm = row
      this.showEdit = true;

      if (this.editForm.pid > 0) {
        this.$http.post('/common/city_list', {
          pid: this.editForm.pid
        }).then(res => {
          let cdata = JSON.parse(res.data)
          this.cityArr = cdata
        })
      }
      if (this.editForm.cid > 0) {
        this.$http.post('/common/area_list', {
          cid: this.editForm.cid
        }).then(res => {
          let adata = JSON.parse(res.data)
          this.districtArr = adata
        })
      }

      // this.$http.get('/limitnumbers/info?id=' + row.id).then(res => {
      //   if (res.data.code === 0) {
      //     if(res.data.data.type==1){
      //       res.data.data.type='出租车'
      //     }else  if(res.data.data.type==2){
      //       res.data.data.type='拼车'
      //     }else if(res.data.data.type==3){
      //       res.data.data.type='帮我买'
      //     }else if(res.data.data.type==4){
      //       res.data.data.type='取送件'
      //     }else if(res.data.data.type==5){
      //       res.data.data.type='超市'
      //     }else {
      //       res.data.data.type='叫餐'
      //     }
      //     res.data.data.pid=res.data.data.pname
      //     res.data.data.cid=res.data.data.cname
      //     res.data.data.aid=res.data.data.aname
      //     this.editForm = res.data.data;
      //     this.showEdit = true;
      //     //console.log( this.editForm)
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // }).catch(e => {
      //   this.$message.error(e.message);
      // });
    },
    /**
     * 保存编辑
     */
    save() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          });
          //console.log(this.editForm)
          // if (this.changePro) {
          // 	this.editForm.pid = this.editForm.pid
          // }
          if (this.changeCity) {
            this.editForm.cid = this.editForm.cname
          }
          if (this.changeArea) {
            this.editForm.aid = this.editForm.aname
          }
          //console.log(this.editForm)
          this.$http.post('/range/save_damages', this.editForm).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.showEdit = false;
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /**
     * 刪除(批量刪除)
     */
    remove(row) {
      //console.log(row)
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
        let ids = this.choose.map(d => d.id);
        this.$confirm('确定要删除选中的设置吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/range/damagesDelete', {
            id: ids
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        }).catch(() => 0);
      } else { // 单个删除
        this.$confirm('确定要删除选中的设置吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          });
          this.$http.post('/range/damagesDelete', {
            id: row.id
          }).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              });
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      }
    },
  }
}
</script>

<style scoped>
.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
  width: 100%;
}

.ele-block>>>.el-upload,
.ele-block>>>.el-upload-dragger {
  width: 100%;
}

.driverForm /deep/.el-form-item__content {
  margin-left: 0 !important
}

/deep/.el-tabs__nav {
  margin-left: 20px
}

.prompt {
  color: #FF0000;
  font-size: 14px;
}
</style>
